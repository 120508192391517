@import "../../global/mixins";

.gettingStartedPage {
    margin-top: calc(var(--nav-height));
    padding: getRem(48) 0 getRem(64);

    .wrapper {
        max-width: getRem(1240);
        margin: 0 auto;
    }

    &>.wrapper {
        padding: 0 getRem(16);
    }

    // .row {
    //     display: flex;
    //     justify-content: space-between;
    // }

    .divider {
        margin: getRem(56) 0 getRem(64);
        border-top: 1px solid #ADADAD;
    }

    section {
        &:not(:last-child) {
            margin-bottom: getRem(56);
        }
    }

    h1 {
        margin: 0;
    }

    h2 {
        @include font(32, 40);
        margin: 0 0 getRem(12);

        &+p {
            margin-top: 0;
        }
    }

    p {
        @include font(21, 41);

        &:first-child {
            margin-top: 0;
        }

        a {
            color: var(--red);
            font-weight: bold;
        }
    }

    .bannerSection {
        border-top: none;

        .row {
            column-gap: getRem(80);
            padding: getRem(48) 0 getRem(64);
        }

        .imageContainer {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                max-width: getRem(170);

                @include breakpoint(mobile) {
                    max-width: none;
                }
            }
        }

        :global {
            .btn {
                @include font(16, 28);
                margin: getRem(8) auto 0;
                padding: getRem(8) getRem(16);
                white-space: nowrap;
            }
        }

        .divider {
            margin-top: 0;
        }
    }

    .gridSection {
        //display: grid;
        //grid-gap: getRem(24) getRem(20);
        //grid-template-columns: repeat(12, 1fr);
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: getRem(24) getRem(20);
        margin-top: getRem(64);

        & > * {
            //grid-column: span 12;
			flex: 0 0 100%;

            &[data-size="20"] {
                //grid-column: span 2;
				flex: 0 0 calc(20% - getRem(20));

                @include breakpoint(tablet) {
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(20));
                }

                @include breakpoint(mobile) {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="25"] {
                //grid-column: span 3;
				flex: 0 0 calc(25% - getRem(20));

                @include breakpoint(tablet) {
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(20));
                }

                @include breakpoint(mobile) {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="33"] {
                //grid-column: span 4;
				flex: 0 0 calc(33% - getRem(20));

                @include breakpoint(tablet) {
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(20));
                }

                @include breakpoint(mobile) {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="50"] {
                //grid-column: span 6;
				flex: 0 0 calc(50% - getRem(20));

                @include breakpoint(mobile) {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="66"] {
                //grid-column: span 8;
				flex: 0 0 calc(66% - getRem(20));

                @include breakpoint(tablet) {
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(20));
                }

                @include breakpoint(mobile) {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="75"] {
                //grid-column: span 9;
				flex: 0 0 calc(75% - getRem(20));

                @include breakpoint(tablet) {
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(20));
                }

                @include breakpoint(mobile) {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }

            &[data-size="100"] {
                //grid-column: span 12;
				flex: 0 0 100%;
            }
        }

        &.row {
            & > * {
				flex: 0 0 calc(50% - getRem(20));
                //grid-column: span 6;
                margin: auto 0;

                &[data-size="20"] {
                    //grid-column: span 2;
					flex: 0 0 calc(20% - getRem(20));

					@include breakpoint(tablet) {
						//grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
					}
	
					@include breakpoint(mobile) {
						//grid-column: span 12;
						flex: 0 0 100%;
					}
                }
    
                &[data-size="25"] {
                    //grid-column: span 3;
					flex: 0 0 calc(25% - getRem(20));

					@include breakpoint(tablet) {
						//grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
					}
	
					@include breakpoint(mobile) {
						//grid-column: span 12;
						flex: 0 0 100%;
					}
                }
    
                &[data-size="33"] {
                    //grid-column: span 4;
					flex: 0 0 calc(33% - getRem(20));

					@include breakpoint(tablet) {
						//grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
					}
	
					@include breakpoint(mobile) {
						//grid-column: span 12;
						flex: 0 0 100%;
					}
                }
    
                &[data-size="50"] {
                    grid-column: span 6;
					flex: 0 0 calc(50% - getRem(20));
	
					@include breakpoint(mobile) {
						//grid-column: span 12;
						flex: 0 0 100%;
					}
                }
    
                &[data-size="66"] {
                    //grid-column: span 8;
					flex: 0 0 calc(66% - getRem(20));

					@include breakpoint(tablet) {
						//grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
					}
	
					@include breakpoint(mobile) {
						//grid-column: span 12;
						flex: 0 0 100%;
					}
                }
    
                &[data-size="75"] {
                    //grid-column: span 9;
					flex: 0 0 calc(75% - getRem(20));

					@include breakpoint(tablet) {
						//grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
					}
	
					@include breakpoint(mobile) {
						//grid-column: span 12;
						flex: 0 0 100%;
					}
                }
    
                &[data-size="100"] {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }
        }

        h2 {
            // margin-bottom: getRem(32);
            margin-bottom: 0;
        }

        .grid {
            //display: grid;
            //grid-gap: getRem(68) getRem(20);
            //grid-template-columns: repeat(12, 1fr);
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap: getRem(68) getRem(20);
            margin-top: getRem(64);

            .gridItem {
                &[data-size="20"] {
                    //grid-column: span 2;
					flex: 0 0 calc(20% - getRem(20));

                    @include breakpoint(tablet) {
                        //grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
                    }
                
                    @include breakpoint(mobile) {
                        //grid-column: span 12;
						flex: 0 0 100%;
                    }
                }

                &[data-size="25"] {
                    //grid-column: span 3;
					flex: 0 0 calc(25% - getRem(20));

                    @include breakpoint(tablet) {
                        //grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
                    }
                
                    @include breakpoint(mobile) {
                        //grid-column: span 12;
						flex: 0 0 100%;
                    }
                }

                &[data-size="33"] {
                    //grid-column: span 4;
					flex: 0 0 calc(33% - getRem(20));

                    @include breakpoint(tablet) {
                        //grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
                    }
                
                    @include breakpoint(mobile) {
                        //grid-column: span 12;
						flex: 0 0 100%;
                    }
                }

                &[data-size="50"] {
                    //grid-column: span 6;
					flex: 0 0 calc(50% - getRem(20));
                
                    @include breakpoint(mobile) {
                        //grid-column: span 12;
						flex: 0 0 100%;
                    }
                }

                &[data-size="66"] {
                    //grid-column: span 8;
					flex: 0 0 calc(66% - getRem(20));

                    @include breakpoint(tablet) {
                        //grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
                    }
                
                    @include breakpoint(mobile) {
                        //grid-column: span 12;
						flex: 0 0 100%;
                    }
                }

                &[data-size="75"] {
                    grid-column: span 9;
					flex: 0 0 calc(75% - getRem(20));

                    @include breakpoint(tablet) {
                        //grid-column: span 6;
						flex: 0 0 calc(50% - getRem(20));
                    }
                
                    @include breakpoint(mobile) {
                        //grid-column: span 12;
						flex: 0 0 100%;
                    }
                }

                &[data-size="100"] {
                    //grid-column: span 12;
					flex: 0 0 100%;
                }
            }
        }

        .videoThumbnail {
            width: 100%;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 0;
            position: relative;

            span {
                width: 100%;
                height: 100%;
                color: transparent;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }

        .imageContainer {
            width: 100%;
            display: inline-block;
            aspect-ratio: 1.63;
            position: relative;

            .linkText {
                width: 100%;
                height: 100%;
                font-size: getRem(2);
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }

        .cta {
            color: var(--red);
            display: inline-block;
            @include font(16, 24);
            font-weight: 700;
            margin-top: getRem(12);

            &:hover {
                text-decoration: underline;
            }

            &:focus {
                outline: 1px solid var(--red);

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    .singleImage {
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;

        tr {
            border-bottom: 2px solid #e5e5e5;

            &:first-of-type {
                border-top: 2px solid var(--yellow);
            }
        }

        td {
            @include font(16, 18);
            padding: getRem(8);
            vertical-align: top;

            &:not(:first-child) {
                border-left: 2px solid var(--yellow);
            }

            * {
                @include font(16, 24);
                vertical-align: top;
            }

            ul {
                &:first-child {
                    margin-top: 0;
                }
            }
        }

        a {
            color: var(--red);
            font-weight: bold;
            text-decoration: underline;
        }
    }

    :global {
        .hasBorder {
            outline: 1px solid #E5E5E5;
        }
    }
}